export const Data=[
    {
      "details": "Prof. Prasenjit Das, Academy of Technology"
    },
    {
      "details": "Prof. Basab Chatterjee, Academy of Technology"
    },
    {
      "details": "Prof. Sirshendu Saha, Academy of Technology"
    },
    {
      "details": "Prof. Avishek Chakraborty, Academy of Technology"
    },
    {
      "details": "Prof. Debasish Sur, Academy of Technology"
    },
    {
      "details": "Prof. Oendrila Samanta, Academy of Technology"
    },
    {
      "details": "Prof. Saurav Kayal, Academy of Technology"
    },
    {
      "details": "Prof. Uday K. Mondal, Academy of Technology"
    },
    {
      "details": "Prof. Subir Panja, Academy of Technology"
    },
    {
      "details": "Prof. Suman Bhattacharya, Academy of Technology"
    },
    {
      "details": "Prof. Baishakhi Bhattacharya, Academy of Technology"
    },
    {
      "details": "Prof. Poulomi Chattopadhyay, Academy of Technology"
    },
    {
      "details": "Prof. Sreetama Sinha, Academy of Technology"
    },
    {
      "details": "Prof. Abhinaba Dutta, Academy of Technology"
    }
  ]
  
