export const imgData=[
  {
    img:"img/IEE.png"
  },
    {
    img:"img/ieee-computer-society-logo.png"
  },
  {
    img:"img/Csilogo.JPG"
  },
  {
    img:"img/IELEI.png"
  },
]