const Data2=[
    {
        personalLink:"#1",
        name:"Dr. Sridhar Mitta",
        // designation:"Head of AI for Chip Design, Sr. Lecturer",
        // topic:"to be updated...",
        details:"Founder & Managing Director, NextWealth Entrepreneurs Pvt. Ltd",
        profileImg: "/img/SridharMitta.png"
    },
    // {
    //     personalLink:"#2",
    //     name:"Dr. Vijayan K. Asari",
    //     designation:"Professor, Director",
    //     topic:"to be updated...",
    //     details:"Professor, Ohio Research Scholars Endowed Chair in Wide Area Surveillance, Director, Center of Excellence for Computational Intelligence and Machine Vision (Vision Lab). School of Engineering: Department of Electrical and Computer Engineering, University of Dayton, Ohio, USA",
    //     profileImg: "/img/Vijayan.png"
    // },
   
]
export default Data2;
