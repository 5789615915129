export const Data =[
    {
      "details": "Prof. Ajoy Kumar Ray, Ex Professor of IIT Kharagpur and Ex Director of IIEST Shibpur, Padma Shri Awardee"
    },
    {
      "details": "Prof. George Shaker, University of Waterloo, Canada"
    },
    {
      "details": "Prof. Sanghamitra Bandyopadhyay, Director, ISI"
    },
    // {
    //     "details": "Prof. Anupam Basu, Raja Ramanna Chair, JU"
    // },
    {
      "details": "Prof. Vijayan K Asari, University of Dayton, USA"
    },
    {
      "details": "Prof. Sanjay Banerjee, Texas, USA"
    },
    {
      "details": "Prof. Partha Pratim Chakrabarti, Ex Director & Professor, Department of Computer Science and Engineering IIT Kharagpur"
    },
    {
      "details": "Prof. Amit Konar, Electronics & Telecommunication Engineering Department, JU"
    },
    {
      "details": "Prof. Nikhil Ranjan Das, Calcutta University"
    },
    {
      "details": "Prof. Shibaji Chakraborty, Jadavpur University"
    },
    {
      "details": "Prof. Partha Pratim Das, Department of Computer Science and Engineering, IIT KGP"
    },
    {
      "details": "Prof. Iti Saha Misra, Department of Electronics & Telecommunication Engineering Department, JU"
    },
    {
      "details": "Prof. Ranjan K. Mallik, Department of Electrical Engineering, IIT Delhi"
    },
    {
      "details": "Prof. Jayanta Mukherjee, Department of Computer Science and Engineering IIT KGP"
    },
    {
      "details": "Prof. Indranil Mukherjee, Ex VC, Maulana Abul Kalam Azad University of Technology"
    },
    {
      "details": "Prof. Prabir Kr Biswas, Head Dept. of Electronics and Electrical Communication Engineering IIT, KGP"
    },
    {
      "details": "Prof. Debashis De, Department of Computer Science and Engineering MAKAUT"
    },
    {
      "details": "Prof. Soumitra Banerjee, Dept. of Physical Sciences, IISER, Kolkata"
    }
  ]
  
