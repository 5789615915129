const Data=[
    // {
    //     personalLink:"#1",
    //     name:"Prof. M. J. Deen",
    //     designation:"Distinguished Professor, Department of Electrical & Computer Engineering, Mcmaster University, Canada",
    //     topic:"to be updated...",
    //     details:"to be updated...",
    //     profileImg: "/img/MJDeen.jpg"
    // },
    // {
    //     personalLink:"#2",
    //     name:"Prof. BMA Rahman",
    //     designation:"Professor of Photonics, Department of Computer Science & Engineering, City University of London, UK",
    //     topic:"to be updated...",
    //     details:"to be updated...",
    //     profileImg: "/img/BMARaman.jpg"
    // },
    // {
    //     personalLink:"#3",
    //     name:"Prof. Rob Roy",
    //     topic:"to be updated...",
    //     details:"to be updated...",
    //     designation:"Executive Vice President, TEOCO, Fairfax, Virginia, USA",
    //     profileImg: "/img/RobRoy.jpg"
    // },
    {
        personalLink:"#2",
        name:"Dr. Vijayan K. Asari",
        // designation:"Professor, Director",
        // topic:"to be updated...",
        details:"Professor, Ohio Research Scholars Endowed Chair in Wide Area Surveillance, Director, Center of Excellence for Computational Intelligence and Machine Vision (Vision Lab). School of Engineering: Department of Electrical and Computer Engineering, University of Dayton, Ohio, USA",
        profileImg: "/img/Vijayan.png"
    },
    {
        personalLink:"#1",
        name:"Dr. George Shaker",
        // designation:"Head of AI for Chip Design, Sr. Lecturer",
        // topic:"to be updated...",
        details:"Professor, Department of Electrical and Computer Engineering, University of Waterloo. Selected by the office of the president of NSERC among a group of ten Canadian professors to discuss Canadian research advancement in the Internet of Things (IoT).",
        profileImg: "/img/GeorgeShaker.png"
    },
    {
        personalLink:"#1",
        name:"Dr. Lorenzo Servadei",
        // designation:"Head of AI for Chip Design, Sr. Lecturer",
        // topic:"to be updated...",
        details:"Head of AI for Chip Design @SonyAI | Sr. Lecturer @TUM Technical University of Munich, Munich, Bavaria, Germany",
        profileImg: "/img/Lorenzo1.png"
    },
    {
        personalLink:"#1",
        name:"Prof. Said Broumi",
        // designation:"Head of AI for Chip Design, Sr. Lecturer",
        // topic:"to be updated...",
        details:"Faculty of Science Ben M’Sik University of Hassan II, Laboratory of Information Processing, Casablanca, Morocco",
        profileImg: "/img/SaidBroumi.png"
    },
    {
        personalLink:"#1",
        name:"Prof. Soheil Shalahshour",
        // designation:"Head of AI for Chip Design, Sr. Lecturer",
        // topic:"to be updated...",
        details:"Faculty of Engineering and Natural Sciences Istanbul Okan University, Turkey",
        profileImg: "/img/SoheilShalahshour.png"
    },
    {
        personalLink:"#1",
        name:"Prof. Takaaki Goto",
        // designation:"Head of AI for Chip Design, Sr. Lecturer",
        // topic:"to be updated...",
        details:"Faculty of Information Sciences and Arts,Toyo University, Kawagoe, Japan",
        profileImg: "/img/TakaakiGoto.png"
    },
    // {
    //     personalLink:"#4",
    //     name:"Prof. Ajay Kr Ray",
    //     // topic:"to be updated...",
    //     details:"Ex-Professor, IIT Kharagpur & Ex-Director, IIEST Shibpur, Padma Shri Awardee",
    //     // designation:"Ex-Professor, IIT Kharagpur & Ex-Director, IIEST Shibpur, Padma Shri Awardee",
    //     profileImg: "/img/AjaiKRRay.jpg"
    // },
    {
        personalLink:"#1",
        name:"Dr. V Ramgopal Rao",
        // designation:"Head of AI for Chip Design, Sr. Lecturer",
        // topic:"to be updated...",
        details:"Vice Chancellor, BITS PILLANI",
        profileImg: "/img/RamgopalRao.png"
    },
    {
        personalLink:"#1",
        name:"Prof. Debashis De",
        // designation:"Head of AI for Chip Design, Sr. Lecturer",
        // topic:"to be updated...",
        details:"Professor, Dept. of Computer Science & Engineering, MAKAUT",
        profileImg: "/img/DebashisDe.png"
    },
    {
        personalLink:"#1",
        name:"Dr. Anindya Mukherjee",
        details:"Infineon Technologies AG, Neubiberg, Germany",
        profileImg: "/img/defaultSpeaker.png"
    },
    {
        personalLink:"#1",
        name:"Dr. Dip Prakash Samajdar",
        details:"Assistant Professor, Electronics & Communications Engineering, Solar Cells and VLSI Devices IIITDM, Jabalpur",
        profileImg: "/img/DipPrakashSamajdar.png"
    },
    {
        personalLink:"#1",
        name:"Prof. Nikhil Ranjan Das",
        details:"Professor, Radio Physics & Electronics, University of Calcutta",
        profileImg: "/img/NikhilRanjanDas.png"
    }
    
]
export default Data;
