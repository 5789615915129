export const Data={
    "chaire":[
        {
            "details": "Prof. Anupam Basu, Raja Ramanna Chair Professor, JU "
        },
        {
            "details": "Prof. Dilip Kumar Maity, Principal, Academy of Technology"
        },
    ],
    "cochaire":[
        {
            "details": "Prof. Abhijit Banerjee, Academy of Technology"
        },
        {
            "details": "Prof. Sandip S. Chowdhury, Academy of Technology"
        },
        {
            "details": "Prof. Hironmay Mondal, Academy of Technology"
        },
        {
            "details": "Prof. Arindrajit Pal, Academy of Technology"
        },
        {
            "details": "Prof. Srinjoy Bid, Academy of Technology"
        },
        {
          "details": "Prof. Prasenjit Das, Academy of Technology"
        },
        {
          "details": "Prof. Uday Kumar Mandal, Academy of Technology"
        },
        {
          "details": "Prof. Suman Bhattacharya, Academy of Technology"
        },
        {
          "details": "Prof. Abhinaba Dutta, Academy of Technology"
        },
        {
          "details": "Prof. Subir Panja, Academy of Technology"
        },
        {
          "details": "Prof. Sirshendu Saha, Academy of Technology"
        },
        {
          "details": "Prof. Niloy Ghosh, Academy of Technology"
        },
        {
          "details": "Prof. Basab K Chatterjee, Academy of Technology"
        },
        
    ],
    "comitte":[
          {
            "details": "Prof. Bhabani P. Sinha, ISI, Kolkata"
          },
          {
            "details": "Dr. Lorenzo Servadei, Head of AI for Chip Design, Sony AI, Sr. Lecturer, TUM Technical University of Munich, Bavaria, Germany"
          },
          {
            "details": "Prof. Debotosh Guha, Radio Physics and Electronics, University of Calcutta"
          },
          {
            "details": "Dr. Souvik Hazra, Infineon Technologies, Neubiberg, Munich, Germany"
          },
          {
            "details": "Prof. Bhaskar Gupta, Jadavpur University"
          },
          {
            "details": "Prof. Chandan Sarkar, Jadavpur University"
          },
          {
            "details": "Prof. J Sivaraman, NIT Rourkela"
          },
          {
            "details": "Prof. Hironmay Saha, IIEST, Shibpur"
          },
          {
            "details": "Prof. Indranil Sengupta, Vice Chancellor, JIS; earlier CSE-IIT Kharagpur"
          },
          {
            "details": "Prof. Basabi Chakraborty, Iwate Prefectural University, Japan"
          },
          {
            "details": "Prof. Sudhesna Sarkar, CSE-ITKGP"
          },
          {
            "details": "Prof. Pallab Dasgupta, CSE-IITKGP"
          },
          // {
          //   "details": "Prof. Jayanta Mukherjee, CSE-IITKGP"
          // },
          // {
          //   "details": "Prof. Mritunjoy Chakaraborty, ECE IIT Kharagpur"
          // },
          // {
          //   "details": "Prof. Tarun Bhattacharya, ECE IIT Kharagpur"
          // },
          // {
          //   "details": "Prof. Prasanta K. Dasgupta, Calcutta University"
          // },
          // {
          //   "details": "Prof. Pradeep K. Saha, Calcutta University"
          // },
          {
            "details": "Prof. Susmita Mitra, ISI, Kolkata"
          },
          {
            "details": "Prof. Santu Sarkar, Calcutta University"
          },
          {
            "details": "Prof. Koushik Mondal, Calcutta University"
          },
          {
            "details": "Prof. Surojit K. Roy, IIEST Kolkata"
          },
          {
            "details": "Prof. Bandana Majumder, IIT Kharagpur"
          },
          {
            "details": "Prof. Amitabha Mondal, Calcutta University"
          },
          {
            "details": "Prof. Suman Sengupta, Academy of Technology"
          },
          {
            "details": "Mr. Joydip Ghosh, Melbourne, Australia"
          }
        // {
        //     "details": "Prof. Bhabani P. Sinha, ISI, Kolkata"
        // },
        // {
        //     "details":"Dr. Lorenzo Servadei, Head of Al for Chip Design Sony Al, Sr. Lecturer, TUM Technical University of Munich, Bavaria, Germany "
        // },
        // {
        //     "details":"Prof. Debotosh Guha, Radio Physics and Electronics, University of Calcutta "
        // },
        // {
        //     "details":"Dr. Souvik Hazra, Infineon Technologies Neubiberg Munich, Germany "
        // },
        // {
        //     "details": "Prof. Bhaskar Gupta, Jadavpur University"
        // },
        // {
        //     "details": "Prof. Chandan Sarkar, Jadavpur University"
        // },
        // {
        //     "details": "Prof. Hironmay Saha, IIEST, Shibpur"
        // },
        // {
        //     "details": "Prof. Indranil Sengupta, Vice Chancellor, JIS; earlier CSE-IIT Kharagpur"
        // },
        // {
        //     "details": "Prof. Basabi Chakraborty, Iwate Prefectural University, Japan"
        // },
        // {
        //     "details": "Prof. Sudhesna Sarkar, CSE- IITKGP"
        // },
        // {
        //     "details": "Prof. Jayanta Mukherjee, CSE- IITKGP"
        // },
        // {
        //     "details": "Prof. Pallab Dasgupta, CSE- IITKGP"
        // },
        // {
        //     "details": "Prof. Mritunjoy Chakaraborty, ECE IIT Kharagpur"
        // },
        // {
        //     "details": "Prof. Tarun Bhattacharya, ECE IIT Kharagpur"
        // },
        // {
        //     "details": "Prof. Prasanta K. Dasgupta, Calcutta University"
        // },
        // {
        //     "details": "Prof. Pradeep K. Saha, Calcutta University"
        // },
        // {
        //     "details": "Prof. Susmita Sur, ISI, Kolkata"
        // },
        // {
        //     "details": "Prof. Santu Sarkar, Calcutta University"
        // },
        // {
        //     "details": "Prof. Koushik Mondal, Calcutta University"
        // },
        // {
        //     "details": "Prof. Surojit k. Roy, Calcutta University"
        // },
        // {
        //     "details": "Prof. Bandana Majumder, IIT Kharagpur"
        // },
        // {
        //     "details": "Prof. Amitabha Mondal, Calcutta University"
        // },
        // {
        //     "details": "Prof. Suman Sengupta, Academy of Technology"
        // }
        
    ]
}

